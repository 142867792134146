import { createStore } from 'vuex';
import { reactive } from 'vue';
import Big from 'big.js';


export default createStore({
state:reactive({

    orderType: {},
    user: {},
    order: {
        warehouse_id: 0,
        to_warehouse_id:0,
        staff_id: 0,
        washer_id:0,
        color_id:0,
        permer_id:0,
        helper_id:0,
        master_id:0,
        entity_id: 0,
        order_status_id:0,
        entity_address_id: 0,
        address_name: '',
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        entity_contact_id: 0,
        name: '',
        telephone: '',
        telephone2: '',
        fax: '',
        mobile: '',
        private_remark: '',
        public_remark: '',
        item_count: 0,
        order_amount:0,
        order_total_amount: 0,
        order_payment_amount: 0,
        payment_method_id:0,
        payment_method_id_2:0,
        coupon_amount:0,
        cash_voucher_amount:0,
        cash_in:0,
        cash_in_2:0,
        entity_point:0,
        ref_no:'',
        order_discount:'',
        view_cost:false,
        shipping_method:'',
        order_number:'',
        order_status:'',

    },
    addresses: {},
    currentAddress: {
        id: '',
        address_name: '',
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
    },
    editing_address: false,

    contacts: {},
    currentContact: {
        id: '',
        name: '',
        telephone: '',
        telephone2: '',
        fax: '',
        mobile: '',
    },
    editing_contact: false,

    orderItems: [],
    currentItem: {
        id: '',
        sku: '',
        title: '',
        price: '',
        discount: '',
        discountPercentage: '',
        net_price: '',
        avg_cost:'',
        quantity: '',
    },
    settings:{},

}),

mutations:{

    setOrderType(state, orderType) {
        state.orderType = orderType
    },
    setWarehouses(state, data) {
        state.warehouses = data;

    },
    setUser(state, data) {

        state.user = data;
    },
    setOrder(state, order) {
        state.order = order;
    },
    setOrderItems(state, orderItems) {
        if(state.orderType.id == 7 || state.orderType.id == 8 ||state.orderType.id == 9){

            for (var i = 0; i < orderItems.length; i++) {
                orderItems[i].returnQuantity=0;
                orderItems[i].amount =0;
                orderItems[i].origin_order_item_id = orderItems[i].id;
            }

            state.orderItems = orderItems;
        }else{
            state.orderItems = orderItems;
        }

    },
    setSettings(state, settings) {

        state.settings = settings;

    },
    setAddresses(state, addresses) {
        state.addresses = addresses
    },
    setContacts(state, contacts) {
        state.contacts = contacts
    },
    setEntityId(state, id) {
        state.order.entity_id = id
    },
    setEntityName(state, name) {
        state.order.entity_name = name
    },

    setEntityPoint(state, point) {
        state.order.entity_point = parseInt(point);
    },

    setOrderDate(state, date) {
        state.order.order_date = date
    },
    setDeliveryDate(state, date) {
        state.order.delivery_date = date
    },


    selectAddress(state, address_id) {
        state.order.entity_address_id = address_id

        for (var i = 0, len = state.addresses.length; i < len; i++) {
            if (state.addresses[i].id == address_id) {
                state.order.address_name = state.addresses[i].address_name;
                state.order.address_line_1 = state.addresses[i].address_line_1;
                state.order.address_line_2 = state.addresses[i].address_line_2;
                state.order.address_line_3 = state.addresses[i].address_line_3;

            }
        }
    },

    updateAddressName(state, data) {
        state.order.address_name = data;
    },

    updateAddressLine1(state, data) {
        state.order.address_line_1 = data;
    },

    updateAddressLine2(state, data) {
        state.order.address_line_2 = data;
    },

    updateAddressLine3(state, data) {
        state.order.address_line_3 = data;
    },


    selectContact(state, contact_id) {
        state.order.entity_contact_id = contact_id

        for (var i = 0, len = state.contacts.length; i < len; i++) {
            if (state.contacts[i].id == contact_id) {
                state.order.name = state.contacts[i].name;
                state.order.telephone = state.contacts[i].telephone;
                state.order.telephone2 = state.contacts[i].telephone2;
                state.order.mobile = state.contacts[i].mobile;
                state.order.fax = state.contacts[i].fax;

            }
        }


    },

    updateContactPerson(state, data) {
        state.order.name = data;
    },
    updateTelephone(state, data) {
        state.order.telephone = data;
    },
    updateTelephone2(state, data) {
        state.order.telephone2 = data;
    },
    updateMobile(state, data) {
        state.order.mobile = data;
    },
    updateFax(state, data) {
        state.order.fax = data;
    },

    updateRef(state, ref_no) {
        state.order.ref_no= ref_no;
    },

    selectWarehouse(state, warehouse_id) {
        state.order.warehouse_id=warehouse_id;
    },
    selectToWarehouse(state, to_warehouse_id) {
       state.order.to_warehouse_id=to_warehouse_id;
    },
    selectStaff(state, staff_id) {
       state.order.staff_id=staff_id;
    },

    selectWasher(state, id) {
       state.order.washer_id=id;
    },
    selectColor(state, id) {
       state.order.color_id=id;
    },
    selectPermer(state, id) {
       state.order.permer_id=id;
    },
    selectHelper(state, id) {
       state.order.helper_id=id;
    },
    selectMaster(state, id) {
       state.order.master_id=id;
    },

    updatePublicRemark(state, public_remark) {
        state.order.public_remark = public_remark
    },
    updatePrivateRemark(state, private_remark) {
        state.order.private_remark = private_remark
    },

    setItemPrice(state, price) {
        //state.currentItem.price = price
        state.currentItem.price= price;
    },
    setItemNetPrice(state, net_price) {

        state.currentItem.net_price=net_price;
    },
    updateItemNetPrice(state) {

        if (state.orderType.id == 3){
            state.currentItem.net_price = Math.floor((state.currentItem.price - state.currentItem.discount));
        }else{
            state.currentItem.net_price =(state.currentItem.price - state.currentItem.discount).toFixed(2);
        }


    },
    setItemDiscount(state, discount) {

        state.currentItem.discount=discount;
        state.currentItem=discountPercentage=((discount / state.currentItem.price) * 100).toFixed(2);

        //state.currentItem.splice(0, 0);
    },
    setItemDiscountPercentage(state, discountPercentage) {

       state.currentItem.discountPercentage=discountPercentage;
        if(state.orderType.id ==3){
           state.currentItem.discount=Math.ceil((state.currentItem.price -(state.currentItem.price * (100 - discountPercentage) / 100)));
        }else{
           state.currentItem.discount=(state.currentItem.price -(state.currentItem.price * (100 - discountPercentage) / 100)).toFixed(2);
        }

    },

    setOrderDiscount(state, discountPercentage){
       state.order.order_duscount=discountPercentage;
        for(var i=0; i<state.orderItems.length; i++){

           state.orderItems[i].discountPercentage=discountPercentage;
           state.orderItems[i].discount=(state.orderItems[i].price-(state.orderItems[i].price * (100 - discountPercentage) / 100)).toFixed(2);
           state.orderItems[i].net_price=(state.orderItems[i].price - state.orderItems[i].discount).toFixed(2);

        }
    },

    setItemQuantity(state, quantity) {
        state.currentItem.quantity = quantity
    },

    selectItem(state, item) {
        //when selecting an item, the id is product, NOT order item item id
        //console.log(item)
        item.product_id = item.id;
        item.id = "";

        //Vue.set(state, 'currentItem', item);
        //state.currentItem = item;
       state.currentItem.product_id= item.product_id;
        state.currentItem.product_id = item.product_id;
        state.currentItem.sku = item.sku;
        state.currentItem.title = item.title;
        state.currentItem.description = item.description;
        state.currentItem.english = item.name_en;
        state.currentItem.chinese = item.name_zh;


        //for PO
        if (state.orderType.id == 1 ) {
            if(item.net_purchase_price!='0.00'){
                state.currentItem.price = item.net_purchase_price;
                state.currentItem.net_price = item.net_purchase_price;
            }else{
                state.currentItem.price = item.purchase_price;
                state.currentItem.net_price = item.purchase_price;
            }


            state.currentItem.title = item.description;
        }

        else if (state.orderType.id == 2) {
            state.currentItem.price = item.wholesale_price;
            state.currentItem.net_price = item.wholesale_price;
            state.currentItem.title = item.description;


            state.currentItem.avg_cost = item.avg_cost;
        }


        else if (state.orderType.id == 3 || state.orderType.id == 10) {
            state.currentItem.price = item.retail_price;
            state.currentItem.net_price = item.retail_price;
            state.currentItem.title = item.description;
        }

        else if (state.orderType.id == 6) {

            state.currentItem.title = item.description;
            if(item.avg_cost>0){
                state.currentItem.price = item.avg_cost;
                state.currentItem.net_price = item.avg_cost;
                state.currentItem.avg_cost = item.avg_cost;
            }

        }

        else{
            state.currentItem.title = item.description;
        }


        for (var i = 0; i < state.warehouses.length; i++) {
            var qty = state.warehouses[i].slug + 'qty';
            state.currentItem.qty=item[qty];

            var qty = state.warehouses[i].slug + 'qtyreal';
           state.currentItem.qty=item[qty];
        }

       state.currentItem= state.currentItem;
       state.order.warehouse_id= state.order.warehouse_id;
        //    state.currentItem.splice(0, 0);

    },
    updateOrderTotalAmount(state) {

        state.order.order_total_amount = 0;
        state.order.order_total_quantity = 0;
        state.order.item_count = state.orderItems.length;


        for (var i = 0; i < state.order.item_count; i++) {
            if (state.orderType.id == 1 || state.orderType.id == 2 || state.orderType.id == 3||state.orderType.id == 10){


                if (state.orderType.id == 1 ){
                    state.order.order_amount = Big(state.order.order_total_amount).plus(Big(state.orderItems[i].quantity).times(state.orderItems[i].price));
                    state.order.order_total_amount = state.order.order_amount.toFixed(2);
                }else{
                    state.order.order_amount = Big(state.order.order_total_amount).plus(Big(state.orderItems[i].quantity).times(state.orderItems[i].net_price));
                    state.order.order_total_amount = state.order.order_amount.toFixed(2);
                }

                state.order.order_total_quantity = Big(state.order.order_total_quantity).plus(Big(state.orderItems[i].quantity)).toFixed(0);

            }else if(state.orderType.id == 7 || state.orderType.id == 8 ||state.orderType.id == 9){


                state.order.order_amount = Big(state.order.order_total_amount).plus(Big(state.orderItems[i].returnQuantity).times(state.orderItems[i].net_price));
                state.order.order_total_amount = state.order.order_amount.toFixed(2);

                state.order.order_total_quantity = Big(state.order.order_total_quantity).plus(Big(state.orderItems[i].returnQuantity)).toFixed(0);


            }else{
                state.order.order_total_quantity = Big(state.order.order_total_quantity).plus(Big(state.orderItems[i].quantity)).toFixed(0);

            }

        }

    },
    setPaymentMethod(state, value) {
        state.order.payment_method_id=value;

    },
    setPaymentMethod2(state, value) {
        state.order.payment_method_id_2=value;

    },
    setCashIn(state, value) {
        state.order.cash_in=value;

    },
    setCashIn2(state, value) {

        state.order.cash_in_2=value;
    },

    updateRepeatedItemNetPrice(state){

        var items = {};

        for (var i = 0; i < state.orderItems.length; i++) {
            items[state.orderItems[i].product_id].total_qty += state.orderItems[i].quantity;
            items[state.orderItems[i].product_id].total_amount += state.orderItems[i].quantity*state.orderItems[i].price;
        }

        console.log(items);
    },


    addItemToOrder(state) {
        //validate quantity
        var normal_add= function(state){
            state.currentItem.amount = Big(state.currentItem.quantity).times(Big(state.currentItem.net_price)).toLocaleString();
            state.orderItems.push(Object.assign({}, state.currentItem));
        }

        var no_price_add= function(state){
            //state.currentItem.amount = Big(state.currentItem.quantity).times(Big(state.currentItem.net_price)).toLocaleString();
            state.orderItems.push(Object.assign({}, state.currentItem));
        }




        if (state.currentItem.quantity >= 1) {

            if(state.orderType.id == 1){
                normal_add(state);

                //updateRepeatedItemNetPrice(state);

            } else if (state.orderType.id == 2 || state.orderType.id == 3 || state.orderType.id == 10) {

                normal_add(state);

            } else if (state.orderType.id == 5 ) {
                var is_new = true;
                for (var i = 0; i < state.orderItems.length; i++) {
                    if (state.orderItems[i].product_id == state.currentItem.product_id) {
                        is_new = false;
                        var new_qty = parseInt(state.orderItems[i].quantity) + parseInt(state.currentItem.quantity);
                        state.orderItems[i].quantity = new_qty;
                        break;
                    }
                }
                if (is_new) {
                    no_price_add(state);
                }
            }else if (state.orderType.id == 6 ){
                //alert('hit');
                normal_add(state);
            }
            else{
                normal_add(state);
            }


            //clear currentIem
            Object.keys(state.currentItem).forEach(v => state.currentItem[v] = '');

            return true;

        } else if (state.currentItem.quantity <0){
            if (state.orderType.id == 6 ){

                normal_add(state);
            }
        } else {

            return false;
        }
    },

    updateRepeatedItemNetPrice(state) {

        var items = new Array();

        for (var i = 0; i < state.orderItems.length; i++) {

            if (items[state.orderItems[i].product_id] == undefined) {
                items[state.orderItems[i].product_id] = {
                    product_id: state.orderItems[i].product_id,
                    total_qty: 0,
                    total_amount: 0,
                    avg_price: 0,
                }
            }

            items[state.orderItems[i].product_id].total_qty += parseInt(state.orderItems[i].quantity);
            items[state.orderItems[i].product_id].total_amount += parseFloat(state.orderItems[i].quantity * state.orderItems[i].price);
            items[state.orderItems[i].product_id].avg_price = items[state.orderItems[i].product_id].total_amount / items[state.orderItems[i].product_id].total_qty;

        }


        state.orderItems.forEach(function (orderItem, indexa) {

            items.forEach(function (item, indexb) {
                console.log(orderItem);
                console.log(item);
                if (orderItem.product_id == item.product_id) {
                    orderItem.net_price = item.avg_price;
                }
            });

        });

    },

    updateItemQuantity(state, data) {

        state.orderItems[data.index].quantity = data.quantity;
        if(state.orderType.id == 1){
            state.orderItems[data.index].amount= Big(state.orderItems[data.index].quantity).times(state.orderItems[data.index].price).toFixed(2);
        }else{
            state.orderItems[data.index].amount= Big(state.orderItems[data.index].quantity).times(state.orderItems[data.index].net_price).toFixed(2);
        }

        state.orderItems.splice(0, 0);

    },

    updateItemReturnQuantity(state, data) {

        state.orderItems[data.index].returnQuantity = data.quantity;
        state.orderItems[data.index].amount= Big(state.orderItems[data.index].returnQuantity).times(state.orderItems[data.index].net_price).toFixed(2);
        state.orderItems.splice(0, 0);

    },



    removeItem(state, index) {
        state.orderItems.splice(index, 1);
    },
    validateOrder(state) {
        //validate all needed data
        var validate = {
            error: false,
            message: '',
        }

        if (!state.order.warehouse_id || state.order.warehouse_id < 1) {
            validate.error = true;
            validate.message = validate.message + "Please select a warehouse.";
        }

        if (!state.order.staff_id || state.order.staff_id < 1) {
            validate.error = true;
            validate.message = validate.message + "\n\r" + 'Please select a salesman.';
        }

        if (!state.orderItems.length > 0) {
            validate.error = true;
            validate.message = validate.message + "Empty Order";
        }

        if (state.orderType.id == 1 || state.orderType.id == 2) {
            //PO and WSO must have a Entity

            if (!state.order.entity_id > 0) {
                validate.error = true;
                validate.message = validate.message + "\n\r" + 'Please select a Entity.';
            }

        }


        if (state.orderType.id == 3 ) {
            //PO and WSO must have a Entity

            if (state.order.payment_method_id == 0) {
                validate.error = true;
                validate.message = validate.message + "\n\r" + 'Please select 1st payment method.';
            }

            if (state.order.cash_in_2>0 &&  state.order.payment_method_id_2== 0) {
                validate.error = true;
                validate.message = validate.message + "\n\r" + 'Please select 2nd payment method.';
            }

            //check user Enought
            var point_needed=0;
            if (state.order.payment_method_id==20){
                point_needed += parseInt(state.order.cash_in);
            }

            if (state.order.payment_method_id_2 == 20) {
                point_needed += parseInt(state.order.cash_in_2);
            }

            if (state.order.entity_point < point_needed){
                validate.error = true;
                validate.message = validate.message + "\n\r" + 'Cash Dollar 不足.';
            }

            //alert((state.order.order_total_amount * state.settings.cash_dollar_max_percentage));
            //check max point
            if (point_needed > (state.order.order_total_amount * state.settings.cash_dollar_max_percentage) ) {
                //alert((state.order_total_amount * state.settings.cash_dollar_max_percentage));
                validate.error = true;
                validate.message = '客戶只可使用 ' + Math.floor(state.order.order_total_amount * state.settings.cash_dollar_max_percentage)+' cash dollar ';
            }




        }

        if (state.orderType.id == 5 ) {
            if(state.order.to_warehouse_id==0||state.order.to_warehouse_id==state.order.warehouse_id){
                validate.error = true;
                validate.message = validate.message + "\n\r" + 'Please select a correct "TO" warehouse.';
            }

        }


        if (validate.error) {

            alert(validate.message);
            console.log('order validate fail.');
            state.validate = false;
        } else {
            console.log('order validate success.');
            state.validate = true;
        }
    },


},

actions:{



    ajaxOrderFull(context, id) {

        axios.get('/admin/orderFull/' + id)
            .then(function(response) {
                context.commit('setOrder', response.data.order);
                console.log('store order:', context.state.order);
                context.commit('setOrderItems', response.data.order_items);
                //context.commit('setAddresses', response.data.addresses);
                //context.commit('setContacts', response.data.contacts);
                //context.commit('selectAddress', response.data.order.entity_address_id);
                //context.commit('selectContact', response.data.order.entity_contact_id);


                //just for BMA and crystal
                //context.commit('selectEntity', response.data.order.entity);
                


                context.commit('updateOrderTotalAmount');
            })
            .catch(function(error) {
                alert(error);
            });
    },



    holdOrder(context, id) {
        if (context.state.ajaxLoading) {
            return false;
        }

        if (context.state.validate) {

        } else {


            return false;
        }
        //pack data

        var data = {
            order: context.state.order,
            order_items: context.state.orderItems,
            order_type: context.state.orderType,
        }

        //ok then send ajax
        context.commit('startAjaxLoading');

        axios.post('/admin/holdOrder', data)
            .then(function(response) {
                context.commit('stopAjaxLoading');
                if (response.data.result == 'success') {
                    window.location.href = response.data.redirect;
                } else if (response.data.result == 'failed') {
                    alert(response.data.message);
                }
            })
            .catch(function(error) {
                context.commit('stopAjaxLoading');
                alert(response.data.message);
            });
    },

    processOrder(context, url) {
        if (context.state.ajaxLoading) {
            console.log('ajaxLoading is ture');
            return false;
        }

        if (!context.state.validate) {
            console.log('order is not validate');
            return false;
        }
        //pack data

        var data = {
            order: context.state.order,
            order_items: context.state.orderItems,
            order_type: context.state.orderType,
        }

        //ok then send ajax
        context.commit('startAjaxLoading');

        var self= this;
        axios.post('/admin/' + url, data)
            .then(function(response) {
                //context.commit('stopAjaxLoading');
                if (response.data.result == 'success') {

                    if(self.state.orderType.id ==3 && self.state.order.id === undefined){
                        //open receipt in window
                        window.location.href ='/admin/exportReceipt/'+response.data.order.id;

                    }else{
                        if(response.data.order){
                            window.open('/admin/exportOrderPDF/'+response.data.order.id);
                        }
                        window.location.href = response.data.redirect;
                    }

                } else if (response.data.result == 'failed') {

                    alert(response.data.message);

                    if(response.data.redirect){
                        window.location.href=response.data.redirect;
                    }


                }
            })
            .catch(function(error) {
                context.commit('stopAjaxLoading');
                alert(error);
            })
            .finally(function() {
                context.commit('stopAjaxLoading');
            });
    },
    ajaxSearchProductBarcode(context, data) {

        if (context.state.ajaxLoading) {
            return false;
        }

        context.commit('startAjaxLoading');
        var self= this;
        axios.get('/admin/searchProduct/barcode?search=' + data.barcode)
            .then(function(response) {
                context.commit('stopAjaxLoading');

                if (response.data == null) {
                    alert('Barcode Saerch Failed.')
                    return;
                }

                context.commit('selectItem', response.data);

                var sdata ={
                    order_type_id:self.state.orderType.id,
                    date:self.state.order.order_date,
                    id:self.state.currentItem.product_id,
                }

                //context.commit('startAjaxLoading');
                context.dispatch('checkDiscount');

                // axios.post('/admin/checkProductDiscount',sdata)
                //     .then(function(response) {
                //         context.commit('stopAjaxLoading');
                //
                //         if (response.data.result == 'success') {
                //             alert('Product in promotion.');
                //             if(response.data.discount.amount!=''){
                //                 context.commit('setItemDiscount',response.data.discount.amount);
                //             }else{
                //                 context.commit('setItemDiscountPercentage',response.data.discount.percentage);
                //             }
                //
                //             context.commit('updateItemNetPrice');
                //
                //         }
                //
                //         context.commit('stopAjaxLoading');
                //
                //     })
                //     .catch(function(error) {
                //         context.commit('stopAjaxLoading');
                //         alert(error);
                //     });




                data.focus.focus();

            })
            .catch(function(error) {
                context.commit('stopAjaxLoading');
                alert(error);
            });

    },
    ajaxVoidOrder(context, id) {

        axios.post('/admin/voidOrder/' + id)
            .then(function(response) {
                if (response.data.result == 'success') {
                    window.location.href = response.data.redirect;
                } else {
                    alert(response.data.message);
                }
            })
            .catch(function(error) {
                alert(error);
            });
    },

    entitySelected(context, entity) {
        var entity_name = entity.name_en;

        if (context.state.orderType.id == 3) {
            // For retail orders, use CRM member data
            entity_name = entity.name + ' Tel:' + entity.telephone;
            context.commit('setEntityName', entity_name);
            context.commit('setEntityId', entity.id);
            context.commit('setEntityPoint', entity.current_point || 0);
        } else {
            entity_name = entity_name + ' Tel:' + entity.phone;
            context.commit('setEntityName', entity_name);
            context.commit('setEntityId', entity.id);
            context.commit('setEntityPoint', entity.point_balance);
        }

        // Keep existing entity functionality
        if (process.env.MIX_SETTING_USE_ENTITY == 1) {
            if (context.state.orderType.id != 3 || true) {
                context.commit('selectStaff', entity.staff_id);
            }

            axios.get('/admin/entityAddress/' + entity.id)
                .then(function(response) {
                    context.commit('setAddresses', response.data);
                })
                .catch(function(error) {
                    alert('Fetch address failed');
                });

            axios.get('/admin/entityContact/' + entity.id)
                .then(function(response) {
                    context.commit('setContacts', response.data);
                })
                .catch(function(error) {
                    alert('Fetch contact failed');
                });

            if (context.state.order.id == undefined) {
                setTimeout(function() {
                    axios.get('/admin/getEnitiyLastAddressContact/' + entity.id)
                        .then(function(response) {
                            if (response.data.result == 'success') {
                                context.commit('selectAddress', response.data.data.entity_address_id);
                                context.commit('selectContact', response.data.data.entity_contact_id);
                            }
                        })
                        .catch(function(error) {
                            alert('Fetch contact failed');
                        });
                }, 500);
            }
        }
    },

    addItemToOrder(context) {
        //validate quantity
        if (!state.currentItem.quantity >= 1) {
            return false;
        } else {
            context.commit('addItemToOrder');



            context.commit('updateOrderTotalAmount');
        }
    },
    checkDiscount(context){

        if (context.state.ajaxLoading) {
            return false;
        }

        context.commit('startAjaxLoading');

        var data ={
            order_type_id:context.state.orderType.id,
            date:context.state.order.order_date,
            id:context.state.currentItem.product_id,
        }

        axios.post('/admin/checkProductDiscount',data)
            .then(function(response) {
                context.commit('stopAjaxLoading');

                if (response.data.result == 'success') {

                    if(response.data.discount.amount>0){
                        context.commit('setItemDiscount',response.data.discount.amount);
                    }else{
                        context.commit('setItemDiscountPercentage',response.data.discount.percentage);
                    }

                    context.commit('updateItemNetPrice');

                }

                context.commit('stopAjaxLoading');

            })
            .catch(function(error) {
                context.commit('stopAjaxLoading');
                alert(error);
            });
    },

    getSettings(context){
        if (context.state.ajaxLoading) {
            return false;
        }

        context.commit('startAjaxLoading');

        axios.post('/admin/getSettings')
            .then(function (response) {
                context.commit('stopAjaxLoading');

                if (response.data.result == 'success') {
                    context.commit('setSettings', response.data.settings);
                    
                   // console.log(response.data.settings);
                }
                

            })
            .catch(function (error) {
                context.commit('stopAjaxLoading');
                alert(error);
            });
    }



},

});
