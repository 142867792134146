<template>

<div>
    <div class="card card-default">
        <div class="card-header">
            <h3 class="card-title">{{translate('general.order_detail')}} {{order.order_number}} ({{order.order_status}})</h3>
        </div>


        <div class="card-body">
            <div class="row">

                <span class="col-md-12">{{translate('general.order_date')}}</span>
                
                <div class="col-md-12 filter-control">
                    <div class="form-group">
                        <input type="text" name="order_date" class="form-control input-sm datetimepicker-input" id="order_date"  
                        data-toggle="datetimepicker" data-target="#order_date" :placeholder="translate('general.order_date')" v-model="order.order_date" autocomplete="off">
                    </div>
                </div>



                <span class="col-md-12">{{translate('general.delivery_date')}}</span>
                <div class="col-md-12 filter-control">
                    <div class="form-group">
                        <input type="text" name="delivery_date" class="form-control input-sm datetimepicker-input" id="delivery_date"  data-toggle="datetimepicker" 
                        data-target="#delivery_date" :placeholder="translate('general.delivery_date')" v-model="order.delivery_date" autocomplete="off">
                    </div>
                </div>


                <span class="col-md-12">{{translate('general.warehouses')}}</span>

                <div class="col-md-12 filter-control">
                    <select name="warehouse_id" class="form-control" v-model="warehouse_id" :disabled="order.order_status_id >= 2">
                        <option value="0">{{translate('general.select_warehouse')}}</option>
                        <option v-for="warehouse in warehouses" :value="warehouse.id">
                            {{warehouse.slug}}
                        </option>
                    </select>
                </div>


                <template v-if="orderType.id==5">

                    <span class="col-md-12">{{translate('general.transfer_to_warehouse')}}</span>
                    <div class="col-md-12 filter-control">

                        <select name="to_warehouse_id" class="form-control" v-model="to_warehouse_id" :disabled="order.order_status_id >= 2">
                            <option value="0">{{translate('general.select_warehouse')}}</option>
                            <option v-for="warehouse in warehouses" :value="warehouse.id" :key="'warehouse'+warehouse.id">
                                {{warehouse.slug}}
                            </option>
                        </select>
                    </div>
                </template>

                <span class="col-md-12">{{translate('general.staff')}}</span>
                <div class="col-md-12 filter-control">
                    <select name="staff_id" class="form-control" v-model="staff_id" :disabled="order.order_status_id == 6">
                        <option value="0">{{translate('general.staff')}}</option>
                        <option v-for="staff in staffs" :value="staff.id" :key="'staff'+staff.id">
                            {{staff.name}}
                        </option>
                    </select>
                </div>



                <template v-if="orderType.id!=5&&orderType.id!=6">
                    <span class="col-md-12">{{translate('general.entity'+orderType.id)}}</span>

                    <div class="col-md-12 filter-control">
                        <search-selecter :selectedValue="order.entity_name" :placeholder="translate('general.search')+' '+translate('general.entity')+' ...'" :ajaxUrl="searchEntity" id="entity_selecter" datatype="entity"
                        @selectitem="entitySelected" :disabled="order.order_status_id == 6"></search-selecter>
                    </div>
 
                    <template v-if="order.entity_id && orderType.id!=1&&env.MIX_SETTING_USE_ENTITY==1&& orderType.id!=3">
                        <span class="col-md-12">{{translate('general.delivery_address')}}</span>
                        <div class="col-md-12 filter-control">
                            <select name="address_id" class="form-control" v-model="address_id" :disabled="order.order_status_id == 6">
                                <option value="0">{{translate('general.select')}} {{translate('general.delivery_address')}}</option>
                                <option v-for="address in orderAddresses" :value="address.id">
                                    {{address.address_name}}<br>
                                    {{address.address_line_1}} {{address.address_line_2}} {{address.address_line_3}}
                                </option>
                            </select>
                        </div>


                        <div class="col-md-12 filter-control">
                            <input type="text" class="form-control" name="address_line_1" v-model="currentAddressName" placeholder="Address Name (不印在單上)" />
                        </div>

                        <div class="col-md-12 filter-control">
                            <input type="text" class="form-control" name="address_line_1" v-model="currentAddress1" placeholder="Address Line 1" />
                        </div>

                        <div class="col-md-12 filter-control">
                            <input type="text" class="form-control" name="address_line_2" v-model="currentAddress2" placeholder="Address Line 2" />
                        </div>

                        <div class="col-md-12 filter-control">
                            <input type="text" class="form-control" name="address_line_3" v-model="currentAddress3" placeholder="Address Line 3" />
                        </div>

                        <span class="col-md-12">Contact Person</span>
                        <div class="col-md-12 filter-control">
                            <select name="contact_id" class="form-control" v-model="contact_id" :disabled="order.order_status_id == 6">
                                <option value="0">Select an Contact</option>
                                <option v-for="contact in orderContacts" :value="contact.id">
                                    {{contact.name}}<br/>
                                    T:{{contact.telephone}} T2:{{contact.telephone2}} M:{{contact.mobile}} F:{{contact.fax}}
                                </option>
                            </select>
                        </div>

                        <span class="col-md-12">{{translate('general.contact_person')}}</span>

                        <div class="col-md-12 filter-control">
                            <input type="text" class="form-control" name="telephone" v-model="name" :placeholder="translate('general.contact_person')" />
                        </div>

                        <span class="col-md-6">Telephone 1</span>
                        <span class="col-md-6">Telephone 2</span>
                        <div class="col-md-6 filter-control">
                            <input type="text" class="form-control" name="telephone" v-model="telephone" :placeholder="translate('general.telephone')" />
                        </div>
                        <div class="col-md-6 filter-control">
                            <input type="text" class="form-control" name="telephone2" v-model="telephone2" :placeholder="translate('general.telephone')+' 2'" />
                        </div>

                        <span class="col-md-6">Mobile</span>
                        <span class="col-md-6">Fax</span>
                        <div class="col-md-6 filter-control">
                            <input type="text" class="form-control" name="mobile" v-model="mobile" :placeholder="translate('general.mobile')" />
                        </div>

                        <div class="col-md-6 filter-control">
                            <input type="text" class="form-control" name="fax" v-model="fax" :placeholder="translate('general.fax')" />
                        </div>
                        
                    </template>
                </template>

                <template v-if="orderType.id!=3">
                    <span class="col-md-12">{{translate('general.delivery_address')}}</span>
                    <div class="col-md-12 filter-control">
                        <textarea name="web_address" class="form-control" v-model="web_address" disabled rows="5"></textarea>
                    </div>
                </template>

                <span class="col-md-12">{{translate('general.shipping_method')}}</span>
                <div class="col-md-12 filter-control">
                    <input type="text" class="form-control" name="shipping_method" v-model="shipping_method" :disabled="true"/>
                </div>

                <span class="col-md-12">{{translate('general.reference_number')}}</span>
                <div class="col-md-12 filter-control">
                    <input type="text" class="form-control" name="ref_no" v-model="ref_no" placeholder="Reference (Customer Order #)" />
                </div>


                <span class="col-md-12" v-if="(orderType.id==2||orderType.id==3)&&env.MIX_ALLOW_ORDER_DISCOUNT==1" >{{translate('general.order_discount')}}</span>
                <div class="col-md-12 filter-control" v-if="(orderType.id==2||orderType.id==3)&&env.MIX_ALLOW_ORDER_DISCOUNT==1" >
                    <input type="number" class="form-control" name="order_discount" v-model="order_discount" :placeholder="translate('general.order_discount')" />
                </div>

                <span class="col-md-12">{{translate('general.public_remark')}}</span>
                <div class="col-md-12 filter-control">
                    <textarea name="public_remark" class="form-control" v-model="public_remark" :disabled="order.order_status_id == 6" rows="4"></textarea>
                </div>

                <span class="col-md-12">{{translate('general.private_remark')}}</span>
                <div class="col-md-12 filter-control">
                    <textarea name="private_remark" class="form-control" v-model="private_remark" :disabled="order.order_status_id == 6" rows="4"></textarea>
                </div>

                <div class="col-md-6 filter-control" v-if="!order.order_status_id">
                    <input type="button" name="add" class="btn btn-primary" @click="addUpdateOrder" :value="translate('general.add')+' '+translate('general.order')" style="width:100%">
                </div>



                <div class="col-md-6 filter-control" v-if="order.order_status_id>0 && order.order_status_id < 6">
                    <input type="button" name="void" class="btn btn-danger" :value="translate('general.void')+' '+translate('general.order')" @click="voidOrder" style="width:100%">
                </div>

                <div class="col-md-6 filter-control" v-if="order.id>0&&order.order_status_id < 6 && order.order_status_id != 99">
                    <input type="button" name="update" class="btn btn-warning" @click="order_date" :value="translate('general.update')+' '+translate('general.order')" style="width:100%">
                </div>

                <div class="col-md-6 filter-control" v-if="order.order_status_id == 2">
                    <input type="button" name="ship" class="btn btn-primary" :value="translate('general.shipping')" @click="shipOrder" style="width:100%" v-if="orderType.id==1">
                    <input type="button" name="hold" class="btn btn-primary" :value="translate('general.hold')" @click="holdOrder" style="width:100%" v-if="orderType.id==2||orderType.id==3||orderType.id==5">
                </div>

                <div class="col-md-6 filter-control" v-if="order.order_status_id == 5||order.order_status_id == 7">
                    <input type="button" name="unship" class="btn btn-primary" value="Un-Shipping" @click="unShipOrder" style="width:100%" v-if="orderType.id==1&&order.order_status_id==5">
                    <input type="button" name="unhold" class="btn btn-primary" value="Un-hold" @click="unHoldOrder" style="width:100%" v-if="(orderType.id==2||orderType.id==3||orderType.id==10)&&order.order_status_id==7">
                    <input type="button" name="unhold" class="btn btn-primary" value="Un-Do" @click="unDoST" style="width:100%" v-if="orderType.id==5&&order.order_status_id==7">
                </div>



                <div class="col-md-6 filter-control" v-if="order.order_status_id==0 || order.order_status_id == 2 || order.order_status_id == 7 || order.order_status_id == 5">
                    <input type="button" name="stockin" class="btn btn-success" :value="translate('general.stock_in')" @click="stockInOrder" style="width:100%" v-if="orderType.id==1">
                    <input type="button" name="stockout" class="btn btn-success" :value="translate('general.stock_out')" @click="stockOutOrder" style="width:100%" v-if="((orderType.id==3||orderType.id==2||orderType.id==5||orderType.id==10)&&order.order_status_id!==undefined&&order.order_status_id>=2&&order.order_status_id<=7)">
                    <input type="button" name="stockin" class="btn btn-success" value="Complete ADJ." @click="completeAdjustment" style="width:100%" v-if="orderType.id==6&&order.order_status_id!==undefined">
                </div>

                <div class="col-md-6 filter-control" v-if="order.order_status_id==8 && user.name=='Sky'||user.senior_manager==1">
                    <input type="button" name="unstockout" class="btn btn-danger" value="UN-Stock" @click="unStockOutOrder" style="width:100%" v-if="(orderType.id==2||orderType.id==3)&&order.order_status_id==8">
                </div>

                <div class="col-md-6 filter-control" v-if="order.order_status_id==6 && user.name=='Sky'||user.senior_manager==1">
                    <input type="button" name="unstockin" class="btn btn-danger" value="UN-Stock" @click="unStockInOrder" style="width:100%" v-if="orderType.id==1&&order.order_status_id==6">
                </div>


                <div class="col-md-6 filter-control" v-if="order.id && orderType.id !=3 && false">
                    <a :href="'/exportOrderExcel/'+order.id" class="btn btn-default" style="width:100%"><i class="fa fa-file-excel-o" aria-hidden="true"></i> Excel</a>
                </div>

                <div class="col-md-6 filter-control" v-if="order.id">
                    <a :href="'/admin/exportOrderPDF/'+order.id" class="btn btn-default" style="width:100%"><i class="fa fa-file-pdf-o" aria-hidden="true"></i> PDF</a>
                </div>

                <div class="col-md-6 filter-control" v-if="order.id && orderType.id ==3">
                    <a :href="'/admin/exportReceipt/'+order.id" class="btn btn-default" style="width:100%"><i class="fa fa-file-pdf-o" aria-hidden="true"></i> {{translate('general.receipt')}}</a>
                </div>


                <div class="col-md-6 filter-control" v-if="order.id && orderType.id ==3 && order.order_status_id == 8 &&order.order_total_amount-order.order_payment_amount>0">
                    <input type="button" name="update" class="btn btn-warning" @click="paidOrder" value="Paid Order" style="width:100%">
                </div>

                <div class="col-md-12 filter-control" v-if="orderType.id == 2 || orderType.id == 3 &&false">
                    <a :href="'/exportOrderDN/'+order.id" class="btn btn-default" style="width:100%"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>{{translate('general.dn')}}</a>
                </div>

                <div class="col-md-12 filter-control" v-if="env.MIX_IMPORT_CSV=='true'&&(!order.id||order.order_status_id==2)&&false">
                    <input type="button" name="import_csv" class="btn btn-default" value="Import CSV" @click="import_csv_modal" style="width:100%" >
                </div>
                <div class="col-md-12 filter-control" v-if="env.MIX_SETTING_ORDER_LABLE=='true'" >
                    <a :href="'/printOrderItemsLabel?id='+order.id" class="btn btn-default"  style="width:100%" v-if=" order.order_status_id >= 2" >Print Label</a>
                </div>
                <div class="col-md-12 filter-control" v-if="env.MIX_SETTING_ORDER_LABLE=='true'" >
                    <a :href="'/addOrderItemsLabel?id='+order.id" class="btn btn-default" style="width:100%" v-if=" order.order_status_id >= 2" >Add Label List</a>
                </div>
                <div class="col-md-12 filter-control" v-if="env.MIX_SETTING_VVIP=='true'">
                    <input type="button" name="vip" class="btn btn-warning" value="Super VIP" @click="super_vip_modal" style="width:100%" v-if="orderType.id ==3 && order.order_status_id <= 2" >
                </div>

                <div class="col-md-12 filter-control" v-if="(orderType.id == 7 || orderType.id == 8 || orderType.id == 9)&&order.order_status_id!=10 ">
                    <input type="button" name="return_order" class="btn btn-danger" value="Return Order" @click="returnOrder" style="width:100%" >
                </div>

            </div>
        </div>

    </div>


    <div class="modal fade" id="upload-csv-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">UPLOAD CSV</h4>
          </div>
          <div class="modal-body">
                Upload a .csv to save you some time!
                <div class="form-group">
                    <label for="exampleInputFile">File input</label>
                    <input type="file" id="csv" name="csv">
                </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" v-on:click="upload_csv">Upload</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="super-vip-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">VVIP DICOUNT</h4>
          </div>
          <div class="modal-body">
              <div class="row">
                  <div class="col-md-3"><input type="button" class="btn btn-primary btn-full" v-on:click="super_vip(10)" value="9折" /></div>
                  <div class="col-md-3"><input type="button" class="btn btn-primary btn-full" v-on:click="super_vip(15)" value="85折" /></div>
                   <div class="col-md-3"><input type="button" class="btn btn-primary btn-full" v-on:click="super_vip(20)" value="8折" /></div>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
</div>

</template>

<script>



import {
    mapState
}
from 'vuex';
import Big from 'big.js';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


export default {
    name: 'order-detail',
    props: {
        warehouses: Array,
        staffs: Array,
        orderid: Number,
        washers: Array,
        colors: Array,
        permers: Array,
        helpers: Array,
        masters: Array,
        ordertype: Object,
        user: Object,
        translations: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    components: {
        VueDatePicker,
        
    },
    
    beforeCreate: function() {

    },
    created: function() {
        this.$store.commit('setWarehouses', this.warehouses);
        this.$store.commit('setUser', this.user);

    },
    mounted: function() {

        //set order type

        console.log(import.meta.env.MODE);
        this.$store.commit('setOrderType', this.ordertype);
        this.$store.dispatch('getSettings');

        if (this.orderid >= 1) {
            this.$store.dispatch('ajaxOrderFull', this.orderid);
            console.log('Order-detail Props:', this.$props);
            console.log('Order-detail order:',this.$store.state.order);
        } else {
            this.$store.commit('selectWarehouse', this.user.warehouse_id);
        }

        $('#order_date').datetimepicker({
            format: 'YYYY-MM-DD'
        });


    },


    data: function() {
        return {
            env:process.env
        }
    },

    computed: {

        orderType: function() {
            return this.$store.state.orderType;
        },
        searchEntity: function() {
            return '/admin/searchEntity/' + this.$store.state.orderType.id;
        },
        order: function() {
            
            console.log(this.$store.state.order);
            return this.$store.state.order;
        },
        orderItems: function() {
            return this.$store.state.orderItems;
        },

        orderAddresses: function() {
            return this.$store.state.addresses;
        },
        web_address:function() {

            return this.$store.state.order.web_address;
        
        },

        shipping_method:function() {
            //return 'test';
            return this.$store.state.order.shipping_method;
        
        },
        currentAddressName: {
            get() {
                    return this.$store.state.order.address_name;
                },
                set(value) {
                    this.$store.commit('updateAddressName', value);
                }
        },
        currentAddress1: {
            get() {
                    return this.$store.state.order.address_line_1;
                },
                set(value) {
                    this.$store.commit('updateAddressLine1', value);
                }
        },
        currentAddress2: {
            get() {
                    return this.$store.state.order.address_line_2;
                },
                set(value) {
                    this.$store.commit('updateAddressLine2', value);
                }
        },
        currentAddress3: {
            get() {
                    return this.$store.state.order.address_line_3;
                },
                set(value) {
                    this.$store.commit('updateAddressLine3', value);
                }
        },
        disable_address: function() {
            return !this.$store.state.editing_address || this.$store.state.order_status_id == 99 || this.$store.state.order_status_id == 6 || this.$store.state.order_status_id == 8;
        },
        orderContacts: function() {
            return this.$store.state.contacts;
        },
        name: {
            get() {
                    return this.$store.state.order.name;
                },
                set(value) {
                    this.$store.commit('updateContactPerson', value);
                }
        },

        telephone: {
            get() {
                    return this.$store.state.order.telephone;
                },
                set(value) {
                    this.$store.commit('updateTelephone', value);
                }
        },
        telephone2: {
            get() {
                    return this.$store.state.order.telephone2;
                },
                set(value) {
                    this.$store.commit('updateTelephone2', value);
                }
        },
        mobile: {
            get() {
                    return this.$store.state.order.mobile;
                },
                set(value) {
                    this.$store.commit('updateMobile', value);
                }
        },

        ref_no: {
            get() {
                    return this.$store.state.order.ref_no;
                },
                set(value) {
                    this.$store.commit('updateRef', value);
                }
        },

        order_discount: {
            get() {
                    return this.$store.state.order.order_discount;
                },
                set(value) {
                    this.$store.commit('setOrderDiscount', value);
                    this.$store.commit('updateOrderTotalAmount');
                }
        },

        fax: {
            get() {
                    return this.$store.state.order.fax;
                },
                set(value) {
                    this.$store.commit('updateFax', value);
                }
        },
        address_id: {
            get() {
                    return this.$store.state.order.entity_address_id;
                },
                set(value) {
                    this.$store.commit('selectAddress', value);
                }
        },
        contact_id: {
            get() {
                    return this.$store.state.order.entity_contact_id;
                },
                set(value) {
                    this.$store.commit('selectContact', value);
                }
        },
        warehouse_id: {
            get() {
                    return this.$store.state.order.warehouse_id
                },
                set(value) {
                    this.$store.commit('selectWarehouse', value);
                }
        },

        to_warehouse_id: {
            get() {
                    return this.$store.state.order.to_warehouse_id
                },
                set(value) {
                    this.$store.commit('selectToWarehouse', value);
                }
        },

        staff_id: {
            get() {

                    if (this.$store.state.order.staff_id > 0) {
                        return this.$store.state.order.staff_id
                    } else {

                        this.$store.commit('selectStaff', this.user.id);
                        return this.user.id;

                    }

                },
                set(value) {
                    this.$store.commit('selectStaff', value);
                }
        },
        washer_id: {
            get() {
                    return this.$store.state.order.washer_id
                },
                set(value) {
                    this.$store.commit('selectWasher', value);
                }
        },
        color_id: {
            get() {
                    return this.$store.state.order.color_id
                },
                set(value) {
                    this.$store.commit('selectColor', value);
                }
        },
        permer_id: {
            get() {
                    return this.$store.state.order.permer_id
                },
                set(value) {
                    this.$store.commit('selectPermer', value);
                }
        },
        helper_id: {
            get() {
                    return this.$store.state.order.helper_id
                },
                set(value) {
                    this.$store.commit('selectHelper', value);
                }
        },
        master_id: {
            get() {
                    return this.$store.state.order.master_id
                },
                set(value) {
                    this.$store.commit('selectMaster', value);
                }
        },
        public_remark: {
            get() {
                    return this.$store.state.order.public_remark;
                },
                set(value) {
                    this.$store.commit('updatePublicRemark', value);
                }
        },
        private_remark: {
            get() {
                    return this.$store.state.order.private_remark;
                },
                set(value) {
                    this.$store.commit('updatePrivateRemark', value);
                }
        },



    },
    watch: {
        orderid(newVal) {
            if (newVal >= 1) {
            this.$store.dispatch('ajaxOrderFull', newVal);
            }
        },
        '$store.state.order.order': {
            handler(newOrder) {
            if (newOrder) {
                // Perform any actions once order is fetched
                console.log('Order updated:', newOrder);
            }
            },
            deep: true,
            immediate: true, // Run handler immediately on initialization
        },
    },

    methods: {

            translate:function(key) {
                // Split the key to handle nested translation values
                const keys = key.split('.');
                let value = this.translations;

                // Iterate through the nested keys to get the actual translation
                for (let i = 0; i < keys.length; i++) {
                    if (value[keys[i]] !== undefined) {
                    value = value[keys[i]];
                    } else {
                    return key; // Fallback to the key itself if translation is not found
                    }
                }

                return value;
            },

            addUpdateOrder: function() {

                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'addUpdateOrder');
            },

            holdOrder: function() {

                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'holdOrder');
            },
            unHoldOrder: function() {

                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'unHoldOrder');
            },

            shipOrder: function() {

                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'shipOrder');
            },
            unShipOrder: function() {

                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'unShipOrder');
            },


            stockOutOrder: function() {

                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'stockOutOrder');
            },
            unStockOutOrder:function(){
                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'unStockOutOrder');
            },

            stockInOrder: function() {
                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'stockInOrder');
            },
            unStockInOrder: function() {
                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'unStockInOrder');
            },


            unDoST: function() {
                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'unDoST');
            },

            paidOrder: function() {
                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'paidOrder');
            },

            completeAdjustment: function() {
                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'completeAdjustment');
            },

            returnOrder: function() {
                this.$store.commit('validateOrder');
                this.$store.dispatch('processOrder', 'returnOrder');
            },

            entitySelected: function(item) {
                this.$store.dispatch('entitySelected', item);
            },

            voidOrder: function() {
                this.$store.dispatch('ajaxVoidOrder', this.order.id);
            },
            test_loading: function() {
                if (this.$store.state.ajaxLoading) {
                    this.$store.commit('stopAjaxLoading');
                } else {
                    this.$store.commit('startAjaxLoading');
                }

            },
            import_csv_modal:function(){
                $('#upload-csv-modal').modal('show');
            },
            upload_csv:function(){

                //alert('csv uploaded');
                var data = new FormData();

                data.append('file', document.getElementById('csv').files[0]);


                var config = {
                    onUploadProgress: function(progressEvent) {
                        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    }
                };

                var self= this;
                axios.post('/admin/uploadProductCSV', data, config)
                .then(function (res) {

                    if(res.data.result=='success'){
                        //alert('hit');
                        for(var i=0 ; i<res.data.data.length; i++){
                            console.log(res.data.data[i])
                            self.$store.commit('selectItem',res.data.data[i]);
                            self.$store.commit('setItemQuantity',res.data.data[i].quantity);

                            if(res.data.data[i].price!=undefined){
                                self.$store.commit('setItemPrice',res.data.data[i].price);
                                self.$store.commit('updateItemNetPrice',res.data.data[i].price);

                            }
                            
                            self.$store.commit('addItemToOrder');
                        }

                        self.$store.commit('updateOrderTotalAmount');
                        alert('missing SKU: ' +res.data.missing_sku);
                    }
                })
                .catch(function (err) {

                });
            },
            super_vip_modal:function(){
                $('#super-vip-modal').modal('show');
            },
            super_vip:function(percent){
                //this.orderDiscountPercentage
                this.$store.commit('setOrderDiscount',percent);
                this.$store.commit('updateOrderTotalAmount');
                this.$store.commit('updatePublicRemark','VIP Discount '+percent+'% off.');
                $('#super-vip-modal').modal('hide');
            }

    },


}

</script>
