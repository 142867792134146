import '../css/app.css';
import './bootstrap';
import { createStore } from 'vuex';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createApp, h } from 'vue';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';



import OrderDetail from './Components/order-detail.vue';
import OrderPanel from './Components/order-panel.vue';
import SearchSelecter from './Components/search-selecter.vue'
import store from './store/order'; 


const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const app = createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue'),
        ),
    setup({ el, App, props, plugin }) {

        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(store)
            .component('order-detail', OrderDetail)
            .component('order-panel', OrderPanel)
            .component('search-selecter', SearchSelecter)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});



