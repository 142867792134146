<template>
<div class="card card-default" style="min-height:100%">
    <div class="card-header">
        <h3 class="card-title">{{translate('general.order_items_card')}}</h3>
    </div>


    <div class="card-body">
        <div class="row">


            <div class="col-md-4">
                <div class="row">
                    <span class="col-xs-6 col-md-5 " style="font-size:18px">{{translate('general.items_count')}}:</span>
                    <span class="col-xs-6 col-md-7 text-right" style="font-size:18px " v-text="order.item_count"></span>
                    <span class="col-xs-6 col-md-5" style="font-size:18px">{{translate('general.items_quantity')}}: </span>
                    <span class="col-xs-6 col-md-7 text-right" style="font-size:18px" v-text="order.order_total_quantity"></span>
                    <span class="col-xs-6 col-md-5" style="font-size:18px">{{translate('general.total')}}: </span>
                    <span class="col-xs-6 col-md-7 text-right" style="font-size:18px">{{Number(order.order_total_amount).toFixed(2)}}</span>
                    <template v-if="orderType.slug!='PO'&&order.order_status_id<=2">
                        <span class="col-xs-6 col-md-5" style="font-size:18px">{{translate('general.stock_level')}}: </span>
                        <span class="col-xs-6 col-md-7 text-right" style="font-size:18px; background-color:green; color:white;" v-if="enoughStock">{{translate('general.enough')}}</span>
                        <span class="col-xs-6 col-md-7 text-right" style="font-size:18px; background-color:red; color:white;" v-if="!enoughStock">{{translate('general.not_enough')}}</span>
                    </template>
                </div>
            </div>

            <div class="col-md-4">
                <div class="row">
                    <span class="col-xs-6 col-md-5 " style="font-size:18px">{{translate('general.member_point')}}: </span>
                    <span class="col-xs-6 col-md-7 text-right" style="font-size:18px" v-text="order.entity_point"></span>

                </div>
            </div>

            <div class="col-md-4">
                <div class="row">
                    <template v-if="orderType.id==3||orderType.id==10">
                        <span class="col-xs-6 col-md-5 " style="font-size:18px; height:40px;">{{translate('general.payment_a')}}:</span>
                        <div class="col-xs-6 col-md-7" style="font-size:18px; height:40px;">
                            <select name="paymentmethod_id" class="form-control" v-model="paymentMethod" >
                                <option value="0">{{translate('general.select')}} {{translate('general.payment_a')}}</option>
                                <option v-for="paymentmethod in paymentmethods" :value="paymentmethod.id">{{paymentmethod.payment_method}}</option>
                            </select>
                        </div>


                        <span class="col-xs-6 col-md-5 " style="font-size:18px; height:40px;">{{translate('general.receive_a')}}:</span>
                        <div class="col-xs-6 col-md-7" style="font-size:18px; height:40px;">
                            <input type="text" name="cash_in" class="form-control" v-model="cashIn" v-on:dblclick="fullPay1" :disabled="order.order_status_id > 2" />
                        </div>


                        <span class="col-xs-6 col-md-5 " style="font-size:18px; height:40px;">{{translate('general.payment_b')}}:</span>
                        <div class="col-xs-6 col-md-7" style="font-size:18px; height:40px;">
                            <select name="paymentmethod_id_2" class="form-control"  v-model="paymentMethod2">
                                <option value="0">{{translate('general.select')}} {{translate('general.payment_b')}}</option>
                                <option v-for="paymentmethod in paymentmethods" :value="paymentmethod.id">{{paymentmethod.payment_method}}</option>
                            </select>
                        </div>


                        <span class="col-xs-6 col-md-5 " style="font-size:18px; height:40px;">{{translate('general.receive_b')}}:</span>
                        <div class="col-xs-6 col-md-7" style="font-size:18px; height:40px;">
                            <input type="text" name="cash_in" class="form-control"  v-model="cashIn2" v-on:dblclick="fullPay2" :disabled="order.order_status_id > 2"/>
                        </div>

                        <span class="col-xs-6 col-md-5 " style="font-size:18px; height:40px;">{{translate('general.changes')}}:</span>
                        <span class="col-xs-6 col-md-7 text-right" style="font-size:18px; height:40px;" v-text="changes"></span>
                    </template>
                </div>
            </div>


            <hr class="col-xs-12" />

            <template v-if="!order.id||order.order_status_id<=2">
                <div class="col-md-2 form-group">
                    <search-selecter :selectedValue="currentItem.sku" :placeholder="translate('general.search')+' '+'SKU ...'" ajaxUrl="/admin/searchProduct/sku" id="sku_selecter" datatype="product" ref="search_sku"
                        @selectitem="selectItem($event)"></search-selecter>
                </div>
                <div class="col-md-4 form-group">
                    <search-selecter :selectedValue="currentItem.description" :placeholder="translate('general.search')+' '+translate('general.product_name')+' ...'" ajaxUrl="/admin/searchProduct/all" id="product_name_selecter" datatype="product"
                        @selectitem="selectItem($event)"></search-selecter>
                </div>

                <typeahead wrapclass="col-md-2 filter-control" :placeholder="translate('general.search')+' '+'SKU'" ref="search_sku" ajaxUrl="/admin/searchProduct/sku" @selectitem="selectItem($event)" :selectedValue="currentItem.sku" datatype="product" ></typeahead>
                <typeahead wrapclass="col-md-4 filter-control" :placeholder="translate('general.search')+' '+translate('general.product_name')" ajaxUrl="/admin/searchProduct/all" @selectitem="selectItem($event)" :selectedValue="currentItem.description" datatype="product" ></typeahead>

                <div class="col-md-2 filter-control" v-if="orderType.id==1||orderType.id==2||orderType.id==3||orderType.id==6">
                    <input class="form-control " name="price" v-model="item_price"  :placeholder="translate('general.price')" />
                </div>
                <div class="col-md-2 filter-control" v-if="orderType.id==2||orderType.id==3">
                    <input class="form-control " name="discount" v-model="item_discount" :placeholder="translate('general.discount')" />
                </div>
                <div class="col-md-2 filter-control" v-if="orderType.id==2||orderType.id==3">
                    <input class="form-control " name="discount_percentage" v-model="item_discount_percentage" :placeholder="translate('general.discount_percentage')" />
                </div>

                <div class="col-md-2 col-md-offset-4 filter-control" v-if="orderType.id==1||orderType.id==2||orderType.id==3">
                    <input class="form-control" name="avg_cost" v-model="item_avg_cost" :placeholder="translate('general.avg_cost')" disabled/>
                </div>

                <div class="col-md-2 filter-control" v-if="orderType.id==2||orderType.id==3">
                    <input class="form-control " name="net_price" v-model="net_price" :placeholder="translate('general.net_price')" disabled/>
                </div>
                <div class="col-md-1 filter-control">
                    <input class="form-control " name="ohqty" ref="ohqty" v-model="ohqty" :placeholder="translate('general.ohqty')" disabled/>
                </div>


                <div class="col-md-1 filter-control">
                    <input class="form-control " name="quantity" ref="quantity" v-model="item_quantity" :placeholder="translate('general.qty')" v-on:keyup.enter.self="add_item_to_order" />
                </div>
                <div class="col-md-2 filter-control">
                    <input type="button" class="form-control btn btn-primary" name="add" :value="translate('general.add')" v-on:click="add_item_to_order" />
                </div>


                <div class="col-md-6 filter-control" v-html="allwhqty" v-if="orderType.id==5||orderType.id==1||orderType.id==2||orderType.id==3">

                </div>

                <div class="col-md-12 filter-control">
                    <search-selecter 
                        :selectedValue="order.entity_name" 
                        :placeholder="searchPlaceholder" 
                        :ajaxUrl="searchEntityUrl" 
                        id="entity_selecter" 
                        datatype="entity"
                        @selectitem="entitySelected" 
                        :disabled="order.order_status_id == 6">
                    </search-selecter>
                </div>

            </template>


            <div class="col-md-12" style="overflow-x:auto;">
                <table class="table order_table">
                    <thead>
                        <tr>
                            <th>SKU</th>
                            <th>{{translate('general.product_name')}}</th>
                            <th class="text-right" v-if="orderType.id!=5">{{translate('general.price')}}</th>
                            <th class="text-right" v-if="orderType.id==2||orderType.id==3">{{translate('general.discount')}}</th>
                            <th class="text-right" v-if="orderType.id!=5">{{translate('general.net_price')}}</th>
                            <th class="text-right" v-if="order.view_cost&&orderType.id!=5">{{translate('general.cost')}}</th>
                            <th class="text-right" v-if="orderType.id==3">LGqty</th>
                            <th class="text-right" >{{currentWHqty}}</th>
                            <th class="text-right">{{translate('general.ohqty')}}</th>
                            <th class="text-right" v-if="(orderType.id==7||orderType.id==8||orderType.id==9)">{{translate('general.return')}}</th>
                            <th class="text-right" v-if="orderType.id!=5">{{translate('general.item_amount')}}</th>
                            <th class="text-center" v-if="orderItemsEditable">{{translate('general.remove')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(orderitem, index) in orderItems">
                            <td><a :href="'/admin/product/'+orderitem.product_id" target="_blank">{{orderitem.sku}}</a></td>

                            <td>
                                {{orderitem.english}}<br />{{orderitem.chinese}}
                            </td>


                            <td class="text-right" v-if="orderType.id!=5">{{Number(orderitem.price).toFixed(2)}}</td>

                            <td class="text-right" v-if="orderitem.discount=='0.00'&&(orderType.id==2||orderType.id==3)"> </td>
                            <td class="text-right" v-if="orderitem.discount!='0.00'&&(orderType.id==2||orderType.id==3)">{{orderitem.discount}}</td>
                            <td class="text-right" v-if="orderType.id!=5">{{Number(orderitem.net_price).toFixed(2)}}</td>
                            <td class="text-right" v-if="order.view_cost&&orderType.id!=5">{{Number(orderitem.cost).toFixed(2)}}</td>

                            <td class="text-right" v-if="orderType.id==3">{{orderitem['LGqty']}}</td>
                            <td class="text-right" style="color:red" v-if="parseInt(orderitem.quantity)>parseInt(orderitem[currentWHqty+'real'])">{{orderitem[currentWHqty]}}</td>
                            <td class="text-right" v-if="orderitem[currentWHqty]!==undefined&&parseInt(orderitem.quantity)<=(orderitem[currentWHqty+'real'])">{{orderitem[currentWHqty]}}</td>


                            <td class="text-right" v-if="order.order_status_id>2">{{orderitem.quantity}}</td>

                            <td class="text-right" v-if="orderItemsEditable">
                                <input type="number" :value="orderitem.quantity" @input="updateItemQuantity(index,$event)" style="max-width:50px; text-align:center; ">
                            </td>

                            <td class="text-right" v-if="(orderType.id==7||orderType.id==8||orderType.id==9)&& order.order_status_id!=10" >

                                <input type="number" :value="orderitem.returnQuantity" @change="updateItemReturnQuantity(index,$event)" style="max-width:50px; text-align:center; " v-if="order.order_status_id!=10">

                                <template v-if="order.order_status_id==10">
                                    {{orderitem.returnQuantity}}
                                </template>
                            </td>

                            <td class="text-right" v-text="Number(orderitem.amount).toFixed(2)" v-if="orderType.id!=5"></td>
                            <td class="text-center" v-if="orderItemsEditable"><i class="fa fa-times" aria-hidden="true" @click="removeItem(index)" style=" cursor: pointer; color:red;"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
//import typeahead from './typeahead.vue';
//import VueBarcodeScanner from 'vue-barcode-scanner';
import Big from 'big.js';
//Vue.use(VueBarcodeScanner,{sensitivity: 30})
export default {
    name: 'order-card',
    props: {
        ordertype: Object,
        orderitems: Array,
        paymentmethods: Array,
        translations: {
            type: Object,
            required: true,
            default: () => ({}),
        }
    },
    data: function() {
        return {
            selected: {},
            items: [],
            qty_total: 0,
            items_count: 0,
            order_total_amount: 0,
            order_total_quantity: 0,
        }
    },
    created(){
            //this.$barcodeScanner.init(this.onBarcodeScanned);
    },
    mounted() {

        if (this.orderitems) {
            this.items = this.orderitems;
        }

        //console.log(this.orderitems);
        this.$store.commit('updateOrderTotalAmount');

    },
    computed: {
        orderType: function() {
            return this.$store.state.orderType;
        },
        order: function() {
            return this.$store.state.order;
        },
        orderItems: function() {
            return this.$store.state.orderItems;
        },
        warehouses: function(){
            return this.$store.state.warehouses;
        },

        orderItemsEditable: function() {
            if (!this.$store.state.order.order_status_id || this.$store.state.order.order_status_id <= 2) {
                return true;
            } else {
                return false;
            }
        },
        currentItem: function() {
            return this.$store.state.currentItem;
        },

        ohqty: {

            get: function() {

                return this.currentItem[this.currentWHqty]
            },
            set: function(value) {

            }



        },

        allwhqty:{

            get: function() {
                var aqty="";


                for (var i = 0; i < this.$store.state.warehouses.length; i++) {

                        if(this.currentItem[this.$store.state.warehouses[i].slug + 'qty']!==undefined){
                            var qty = '<span class="allwhqty">'+this.currentItem[this.$store.state.warehouses[i].slug + 'qty']+'</span>';
                        }else{
                            var qty = '';
                        }

                        var wh ='<span class="allwhqty">'+this.$store.state.warehouses[i].slug+ ': </span>';
                        aqty = aqty + wh +qty+' ';

                }



                return aqty
            },
            set: function(value) {

            }



        },
        cashIn:{
            get: function() {

                return this.$store.state.order.cash_in
            },
            set: function(value) {
                this.$store.commit('setCashIn', value);
            }
        },
        cashIn2:{
            get: function() {

                return this.$store.state.order.cash_in_2
            },
            set: function(value) {
                this.$store.commit('setCashIn2', value);
            }
        },
        cashIn2:{
            get: function() {

                return this.$store.state.order.cash_in_2
            },
            set: function(value) {
                this.$store.commit('setCashIn2', value);
            }
        },
        paymentMethod:{
            get: function() {
                return this.$store.state.order.payment_method_id
            },
            set: function(value) {
                this.$store.commit('setPaymentMethod', value);
            }
        },
        paymentMethod2:{
            get: function() {
                return this.$store.state.order.payment_method_id_2
            },
            set: function(value) {
                this.$store.commit('setPaymentMethod2', value);
            }
        },
        changes:function(){

            if(!this.$store.state.order.cash_in){
                return '--';
            }

            var total= new Big(this.$store.state.order.order_total_amount);
            var paid1= new Big(this.$store.state.order.cash_in);
            var paid2= new Big(this.$store.state.order.cash_in_2);
            var change = paid1.plus(paid2).minus(total).toFixed(2);

            return change;
        },
        enoughStock: function() {
            if (this.$store.state.orderItems.length == 0) {
                return 'no-item';
            } else {
                var enough = true;

                for (var i = 0; i < this.$store.state.orderItems.length; i++) {
                    // Skip stock checking if item has crm_coupon_id
                    if (this.$store.state.orderItems[i].crm_coupon_id) {
                        continue;
                    }

                    if(this.$store.state.order.order_type_id==6){
                        console.log('quantity:'+parseInt(this.$store.state.orderItems[i].quantity)+'  ohqty:'+parseInt(this.$store.state.orderItems[i][this.currentWHqty]));
                        if ((parseInt(this.$store.state.orderItems[i].quantity) + parseInt(this.$store.state.orderItems[i][this.currentWHqty]))<0) {
                            enough = false;
                        }
                    }else{
                        if (parseInt(this.$store.state.orderItems[i].quantity) > parseInt(this.$store.state.orderItems[i][this.currentWHqty])) {
                            enough = false;
                        }
                    }
                }
            }

            return enough;
        },

        currentWHqty: function() {
            for (var i = 0; i < this.$store.state.warehouses.length; i++) {
                if (this.$store.state.order.warehouse_id == this.$store.state.warehouses[i].id) {
                    return this.$store.state.warehouses[i].slug + 'qty';
                }
            }
            return 'Allqty';
        },
        item_price: {
            get: function() {
                return this.$store.state.currentItem.price;
            },
            set: function(value) {
                this.$store.commit('setItemPrice', value);

                this.$store.commit('updateItemNetPrice');

            }
        },
        net_price: {
            get: function() {
                return this.$store.state.currentItem.net_price;
            },
            set: function(value) {
                this.$store.commit('setItemNetPrice', value);
            }
        },
        item_avg_cost: {
            get: function() {
                return this.$store.state.currentItem.avg_cost;
            },
            set: function(value) {

            }
        },
        item_discount: {
            get: function() {
                return this.$store.state.currentItem.discount;
            },
            set: function(value) {
                this.$store.commit('setItemDiscount', value);
                this.$store.commit('updateItemNetPrice');
            }
        },
        item_discount_percentage: {
            get: function() {
                return this.$store.state.currentItem.discountPercentage;
            },
            set: function(value) {
                this.$store.commit('setItemDiscountPercentage', value);
                this.$store.commit('updateItemNetPrice');
            }

        },

        item_quantity: {
            get: function() {
                return this.$store.state.currentItem.quantity;
            },
            set: function(value) {
                this.$store.commit('setItemQuantity', value);
            }
        },
        searchPlaceholder() {
            return this.orderType.id == 3 
                ? this.translate('general.search') + ' ' + this.translate('general.member') 
                : this.translate('general.search') + ' ' + this.translate('general.entity');
        },
        searchEntityUrl() {
            return this.orderType.id == 3 
                ? '/api/searchCrmMember' 
                : '/admin/searchEntity';
        }
    },
    methods: {
        translate:function(key){
                // Split the key to handle nested translation values
                const keys = key.split('.');
                let value = this.translations;

                // Iterate through the nested keys to get the actual translation
                for (let i = 0; i < keys.length; i++) {
                    if (value[keys[i]] !== undefined) {
                    value = value[keys[i]];
                    } else {
                    return key; // Fallback to the key itself if translation is not found
                    }
                }

                return value;
            },
        selectItem(item) {
            //check discount
            this.$store.commit('selectItem', item);
            this.$store.dispatch('checkDiscount');
            this.$refs.quantity.focus();
        },
        removeItem(index) {
            this.$store.commit('removeItem', index);
            if (this.$store.state.orderType.id == 1){
                this.$store.commit('updateRepeatedItemNetPrice');
            }
            this.$store.commit('updateOrderTotalAmount');
        },
        updateItemQuantity(index,event) {

            if(event.target.value>0){

                this.$store.commit('updateItemQuantity', {
                    quantity: event.target.value,
                    index: index
                });
                this.$store.commit('updateOrderTotalAmount');


            }else{

                this.$store.commit('updateItemQuantity', {
                    quantity: this.$store.state.orderItems[index].quantity,
                    index: index
                });

            }


        },

        updateItemReturnQuantity(index,event) {

            if(event.target.value>0&&event.target.value<=this.$store.state.orderItems[index].quantity){

                this.$store.commit('updateItemReturnQuantity', {
                    quantity: event.target.value,
                    index: index
                });
                this.$store.commit('updateOrderTotalAmount');


            }else{

                this.$store.commit('updateItemReturnQuantity', {
                    quantity: this.$store.state.orderItems[index].quantity,
                    index: index
                });

            }


        },

        fullPay1(){
            this.cashIn = this.$store.state.order.order_total_amount;
        },

        fullPay2(){
            this.cashIn2 = this.$store.state.order.order_total_amount-this.cashIn;
        },

        add_item_to_order() {
            //validate quantity
            if(this.$store.state.orderType.id == 6){
                if (this.currentItem.product_id >= 1&&this.currentItem.quantity!='') {


                    if(this.currentItem.price==''){
                        alert('Please set a price.');
                        return false;
                    }

                    this.$store.commit('addItemToOrder')
                    this.$store.commit('updateOrderTotalAmount');
                    this.$refs.search_sku.focus();
                    this.$store.commit('selectItem', {});
                } else {

                    return false;


                }
            }else{

                if (this.currentItem.product_id >= 1) {

                    if(this.currentItem.quantity==''){
                        this.$store.commit('setItemQuantity', 1);
                    }

                    if(!this.currentItem.price){
                        // alert('Please set a price.');
                        // return false;
                    }

                    this.$store.commit('addItemToOrder')

                    if (this.$store.state.orderType.id == 1){
                        this.$store.commit('updateRepeatedItemNetPrice');
                    }


                    this.$store.commit('updateOrderTotalAmount');
                    this.$refs.search_sku.focus();
                    this.$store.commit('selectItem', {});
                } else {
                    //this.$store.commit('setItemQuantity', 1);
                    return false;
                }


            }

        },
        preventEnter(event){

            if (event) event.preventDefault();
        },
        onBarcodeScanned(barcode) {

            console.log(barcode)

            var data={
                barcode:barcode,
                focus:this.$refs.quantity,
            }

            this.$store.dispatch('ajaxSearchProductBarcode', data);

        },
        // Reset to the last barcode before hitting enter (whatever anything in the input box)
        resetBarcode() {
            let barcode = this.$barcodeScanner.getPreviousCode()

        },
        entitySelected(entity) {
            this.$store.commit('entitySelected', entity);
        }


    }

}
</script>
